<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          label-width="200px"
          label-position="right"
          size="mini"
        >
          <el-form-item label="账号" prop="account">
            <el-input v-model="form.account" placeholder="请填写账号"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="account">
            <el-input v-model="form.email" placeholder="请填写邮箱"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="form.password"
              :placeholder="form.guid ? '为空则不更新原密码' : ''"
            ></el-input>
          </el-form-item>
          <el-form-item style="float:right">
            <el-button type="danger" @click="onDelete" v-if="form.guid !== ''"
              >删除</el-button
            >
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      form: {
        guid: "",
        account: "",
        password: "",
        email: ""
      },
      userInfo: {},
    };
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getUserDetail(self.$route.query.guid || null);
  },
  methods: {
    // 获取管理员详情
    getUserDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/api/admin/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function(response) {
            self.form.guid = response.data.data.guid;
            self.form.account = response.data.data.account;
            self.form.email = response.data.data.email;
            self.form.password = "";
          })
          .catch(function(error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      }
    },
    // 保存
    onSubmit() {
      let self = this;
      self.$refs["form"].validate((valid) => {
        if (valid) {
          self.form.areaRelationList = self.form.communityList;
          let data = JSON.parse(JSON.stringify(self.form));
          self.$axios
            .post("/api/admin/saveOrUpdate", data, {
              headers: {
                "content-type": "application/json",
                token: self.userInfo.token,
              },
            })
            .then(function() {
              self.$message({
                message: "操作成功",
                type: "success",
              });
              self.$router.push({ name: "admin_list" });
            })
            .catch(function(error) {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        } else {
          self.$message({
            message: "请输入正确的管理员信息",
            type: "warning",
          });
          return false;
        }
      });
    },
    // 删除
    onDelete() {
      let self = this;
      self
        .$confirm("确定删除 " + (self.form.account || "未命名用户") + "？")
        .then(() => {
          self.$axios
            .delete("/api/admin/delete", {
              data: { guid: self.form.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.$router.push({ name: "admin_list" });
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    }
  },
};
</script>

<style>
  .el-transfer-panel{
  width: 30%;
}
</style>
